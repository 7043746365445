import { FC, useCallback, useMemo } from 'react';
import {
  DesignTokensParser,
  DesignTokenTypeEnum,
  isAlias,
} from '@juxio/design-tokens';
import { useStoreActions } from '@jux/canjux/core';
import { CORE } from '@jux/types';
import {
  FieldContainer,
  StyledSelectField,
  StyledTextField,
  TITLE_CREATE_NEW_TOKEN,
  TITLE_EDIT_TOKEN,
  TokenDrawerFormLayout,
  TokenDrawerIconLabel,
  TokenNameField,
} from '@jux/ui/components/tokens/token-drawer/base';
import {
  ALIAS_PLACEHOLDER_TEXT,
  FONT_FAMILY_PATH_PREFIX,
  fontWeightOptions,
  formatAliasPath,
  formatGroupPath,
  GetValueFn,
  parseTypographyTokenValue,
  renderFontWeightOption,
  TYPOGRAPHY_PATH_PREFIX,
  useFontFamilyOptions,
  useFormValueAlias,
  useInitialValues,
  useRegisterFieldFns,
  useValidateTokenName,
} from '@jux/ui/components/tokens/token-drawer/forms/helpers';
import { formatFontFamilyTokenValue } from '@jux/ui/components/tokens/token-drawer/forms/helpers/formatFontFamilyTokenValue';
import { TypographyTokenFormProps } from '@jux/ui/components/tokens/token-drawer/forms/types';
import { useTokenDrawer } from '@jux/ui/components/tokens/token-drawer/useTokenDrawer';
import { getUpdatedTokenData } from '@jux/ui/components/tokens/token-drawer/utils/getUpdatedTokenData';
import { useZodForm } from '@jux/ui/hooks/useZodForm';
import { typographyTokenInputSchema } from '@jux/ui/trpc/validations';
import { createPath } from '@jux/ui/utils/tokensPath';

export const TypographyTokenForm: FC<TypographyTokenFormProps> = ({
  initialValues,
  groupPathOptions,
  existingTokenPaths = [],
  existingCoreTokenPaths = [],
  isCoreTokenSet,
  tokens,
  valuesMap,
  componentsThatUseToken,
}) => {
  const {
    tokenSetsActions: { setToken },
  } = useStoreActions();

  const defaultValues = {
    ...initialValues,
    value: parseTypographyTokenValue(initialValues.value),
  };

  const form = useZodForm({
    schema: typographyTokenInputSchema,
    defaultValues,
  });

  const { handlers } = useTokenDrawer();

  const [
    registerTokenNameField,
    registerGroupPathField,
    registerAliasField,
    registerFontFamilyField,
    registerFontWeightField,
    registerFontSizeField,
    registerLineHeightField,
    registerLetterSpacingField,
    registerDescriptionField,
  ] = useRegisterFieldFns(form, [
    'tokenName',
    'groupPath',
    {
      alias: {
        onChange: (e) => handlers.onChange?.({ newValue: e.target.value }),
      },
    },
    {
      'value.fontFamily': {
        onChange: (e) =>
          handlers.onChange?.({
            newValue: e.target.value,
            subFieldName: 'fontFamily',
          }),
      },
    },
    {
      'value.fontWeight': {
        onChange: (e) =>
          handlers.onChange?.({
            newValue: e.target.value,
            subFieldName: 'fontWeight',
          }),
      },
    },
    {
      'value.fontSize': {
        onChange: (e) =>
          handlers.onChange?.({
            newValue: e.target.value,
            subFieldName: 'fontSize',
          }),
      },
    },
    {
      'value.lineHeight': {
        onChange: (e) =>
          handlers.onChange?.({
            newValue: e.target.value,
            subFieldName: 'lineHeight',
          }),
      },
    },
    {
      'value.letterSpacing': {
        onChange: (e) =>
          handlers.onChange?.({
            newValue: e.target.value,
            subFieldName: 'letterSpacing',
          }),
      },
    },
    'description',
  ]);

  // using controlled fields to support aliases.
  // each field has a default/initial value, but if the user has changed the value,
  // we want to use the value they have changed it to, rather than the default value.
  const [
    fontFamilyValue,
    fontWeightValue,
    fontSizeValue,
    lineHeightValue,
    letterSpacingValue,
  ] = useInitialValues(
    [
      'value.fontFamily',
      'value.fontWeight',
      'value.fontSize',
      'value.lineHeight',
      'value.letterSpacing',
    ],
    {
      form,
      initialValues: defaultValues,
    }
  );

  const getValue: GetValueFn = useCallback(
    (valuePath) =>
      parseTypographyTokenValue(
        (tokens
          ? new DesignTokensParser(tokens).getTokenRawValue(valuePath, true)
          : valuesMap[
              valuePath
            ]) as TypographyTokenFormProps['initialValues']['value']
      ),
    [tokens, valuesMap]
  );

  const existingTypographyTokens = useMemo(() => {
    if (isCoreTokenSet) {
      return existingCoreTokenPaths.filter((p) =>
        p.startsWith(createPath([CORE, TYPOGRAPHY_PATH_PREFIX]))
      );
    }

    return existingTokenPaths.filter((p) =>
      p.startsWith(TYPOGRAPHY_PATH_PREFIX)
    );
  }, [existingCoreTokenPaths, existingTokenPaths, isCoreTokenSet]);

  const existingCoreTypographyTokens = useMemo(
    () =>
      existingCoreTokenPaths.filter((p) =>
        p.startsWith(createPath([CORE, TYPOGRAPHY_PATH_PREFIX]))
      ),
    [existingCoreTokenPaths]
  );

  const { aliasOptionsProps, hasAliases, aliasValue, isAliasToken } =
    useFormValueAlias<typeof form, typeof typographyTokenInputSchema>({
      form,
      getValue,
      existingTokenPaths: existingTypographyTokens,
      isCoreTokenSet,
      existingCoreTokenPaths: existingCoreTypographyTokens,
    });

  const validateTokenName = useValidateTokenName({
    form,
    existingTokenPaths: existingTypographyTokens,
  });

  const { options: fontFamilies, fallbacks: fontFamilyFallbacks } =
    useFontFamilyOptions();

  const handleSubmit = useMemo(
    () =>
      form.handleSubmit((values) => {
        if (validateTokenName()) {
          const fontFamily = isAlias(values.value.fontFamily)
            ? values.value.fontFamily
            : formatFontFamilyTokenValue({
                value: values.value.fontFamily,
                fallbacks:
                  fontFamilyFallbacks[
                    values.value.fontFamily as unknown as string
                  ],
              });

          setToken({
            data: {
              ...values,
              alias: formatAliasPath(values.alias),
              isCore: isCoreTokenSet,
              value: {
                ...values.value,
                fontFamily,
              },
            },
            type: DesignTokenTypeEnum.typography,
          });

          handlers.onSave(
            getUpdatedTokenData({
              tokenPath: createPath([values.groupPath, values.tokenName]),
              tokenSetId: values.tokenSetId,
            })
          );
        }
      }, validateTokenName),
    [
      form,
      validateTokenName,
      fontFamilyFallbacks,
      setToken,
      isCoreTokenSet,
      handlers,
    ]
  );

  const existingFontFamilyTokenPaths = useMemo(
    () =>
      existingTokenPaths.filter((p) => p.startsWith(FONT_FAMILY_PATH_PREFIX)),
    [existingTokenPaths]
  );

  const groupedFontFamilyOptions = useMemo(
    () => ({
      tokens: existingFontFamilyTokenPaths.map((p) => ({
        label: formatGroupPath(p),
        value: formatAliasPath(p),
      })),
      'font family': fontFamilies.map((f) => ({
        label: f,
        value: f,
      })),
    }),
    [existingFontFamilyTokenPaths, fontFamilies]
  );

  return (
    <TokenDrawerFormLayout
      title={
        initialValues.tokenName ? TITLE_EDIT_TOKEN : TITLE_CREATE_NEW_TOKEN
      }
      componentsThatUseToken={componentsThatUseToken}
      onSubmit={handleSubmit}
    >
      <TokenDrawerIconLabel iconVariant="TYPOGRAPHY" labelText="Typography" />
      <FieldContainer error={form.formState.errors.tokenName?.message}>
        <TokenNameField
          error={Boolean(form.formState.errors.tokenName)}
          inputRef={registerTokenNameField().ref}
          {...registerTokenNameField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.groupPath?.message}>
        <StyledSelectField
          placeholder="Folder"
          defaultValue={initialValues.groupPath}
          renderValue={(item) => formatGroupPath(item, isCoreTokenSet)}
          error={Boolean(form.formState.errors.groupPath)}
          inputRef={registerGroupPathField().ref}
          options={groupPathOptions}
          disableMenuPortal
          {...registerGroupPathField()}
        />
      </FieldContainer>
      <FieldContainer>
        <StyledSelectField
          disabled={!hasAliases}
          placeholder={ALIAS_PLACEHOLDER_TEXT}
          value={aliasValue}
          renderValue={(item) => formatGroupPath(item, isCoreTokenSet)}
          error={Boolean(form.formState.errors.alias)}
          inputRef={registerAliasField().ref}
          disableMenuPortal
          {...aliasOptionsProps}
          {...registerAliasField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.value?.fontFamily?.message}>
        <StyledSelectField
          disabled={isAliasToken}
          placeholder="Font family"
          value={fontFamilyValue}
          error={Boolean(form.formState.errors.value?.fontFamily)}
          groupedOptions={groupedFontFamilyOptions}
          inputRef={registerFontFamilyField().ref}
          disableMenuPortal
          {...registerFontFamilyField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.value?.fontWeight?.message}>
        <StyledSelectField
          disabled={isAliasToken}
          placeholder="Font weight"
          value={fontWeightValue}
          error={Boolean(form.formState.errors.value?.fontWeight)}
          options={fontWeightOptions}
          renderValue={renderFontWeightOption}
          inputRef={registerFontWeightField().ref}
          disableMenuPortal
          {...registerFontWeightField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.value?.fontSize?.message}>
        <StyledTextField
          disabled={isAliasToken}
          value={fontSizeValue}
          inputProps={{
            placeholder: 'Font size',
          }}
          error={Boolean(form.formState.errors.value?.fontSize)}
          inputRef={registerFontSizeField().ref}
          {...registerFontSizeField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.value?.lineHeight?.message}>
        <StyledTextField
          disabled={isAliasToken}
          value={lineHeightValue}
          inputProps={{
            placeholder: 'Line height',
          }}
          error={Boolean(form.formState.errors.value?.lineHeight)}
          inputRef={registerLineHeightField().ref}
          {...registerLineHeightField()}
        />
      </FieldContainer>
      <FieldContainer
        error={form.formState.errors.value?.letterSpacing?.message}
      >
        <StyledTextField
          disabled={isAliasToken}
          value={letterSpacingValue}
          inputProps={{
            placeholder: 'Letter spacing',
          }}
          error={Boolean(form.formState.errors.value?.letterSpacing)}
          inputRef={registerLetterSpacingField().ref}
          {...registerLetterSpacingField()}
        />
      </FieldContainer>
      <FieldContainer error={form.formState.errors.description?.message}>
        <StyledTextField
          inputProps={{
            placeholder: 'Token description',
          }}
          error={Boolean(form.formState.errors.description)}
          inputRef={registerDescriptionField().ref}
          {...registerDescriptionField()}
        />
      </FieldContainer>
    </TokenDrawerFormLayout>
  );
};
