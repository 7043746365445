import { useCallback } from 'react';
import {
  getComponentsThatUseTokenPathSelector,
  storeApi,
  useStoreActions,
} from '@jux/canjux/core';
import { useEditTokenForm } from '@jux/ui/components/tokens/token-drawer/forms/createEditHooks';
import { useTokenSetState } from '@jux/ui/state';
import { createPath } from '@jux/ui/utils/tokensPath';
import { useSelectedTokenState } from '../state/useSelectedTokenState';
import { DisableDeleteReturnType } from '../interface';

export const useDeleteToken = () => {
  const { isTokenSelected, deselectToken } = useSelectedTokenState();
  const { closeEditTokenForm } = useEditTokenForm();
  const { currentTokenSetId } = useTokenSetState();
  const {
    tokenSetsActions: { deleteTokenOrGroup },
  } = useStoreActions();

  const deleteToken = useCallback(
    ({ tokenName, groupPath }: { tokenName: string; groupPath: string }) => {
      if (!currentTokenSetId) return;

      const deletePath = createPath([groupPath, tokenName]);

      deleteTokenOrGroup({
        path: deletePath,
      });

      if (isTokenSelected(deletePath)) {
        deselectToken();
        closeEditTokenForm();
      }
    },
    [
      closeEditTokenForm,
      currentTokenSetId,
      deleteTokenOrGroup,
      deselectToken,
      isTokenSelected,
    ]
  );

  const shouldDisableDeleteToken = useCallback(
    ({
      tokenName,
      groupPath,
      hasAlias,
    }: {
      tokenName: string;
      groupPath: string;
      hasAlias: boolean;
    }): DisableDeleteReturnType => {
      if (hasAlias) {
        return {
          disabled: true,
          reason: 'This token has one or more aliases',
        };
      }

      const deletePath = createPath([groupPath, tokenName]);

      const componentsThatUsesTokenPath = getComponentsThatUseTokenPathSelector(
        storeApi.getState()
      )(deletePath);
      const amountOfComponentsThatUsesTokenPath = Object.keys(
        componentsThatUsesTokenPath
      ).length;

      if (amountOfComponentsThatUsesTokenPath > 0) {
        let reason = '';

        if (amountOfComponentsThatUsesTokenPath === 1) {
          reason = 'Token is used by a component';
        } else {
          reason = `Token is used by ${amountOfComponentsThatUsesTokenPath} components`;
        }

        return {
          disabled: true,
          reason,
        };
      }

      return {
        disabled: false,
        reason: '',
      };
    },
    []
  );

  return {
    deleteToken,
    shouldDisableDeleteToken,
  };
};
