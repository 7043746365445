import { FC } from 'react';
import { MenuList, Tooltip, Typography } from '@jux/ui/components';
import { MuiBox, MuiMenuItem } from '@jux/ui/components/common/mui';
import { DisableDeleteReturnType } from '../../interface';

export const TokenCardActionsMenu: FC<{
  onDeleteToken: () => void;
  disableDeleteToken: DisableDeleteReturnType;
  isTokenCardHovered?: boolean;
}> = ({ disableDeleteToken, isTokenCardHovered, onDeleteToken }) => (
  <MenuList
    isVisible={isTokenCardHovered}
    optionsButtonProps={{
      variant: 'icon',
      disableTooltip: true,
    }}
    paperProps={{
      sx: {
        marginTop: '-1px',
      },
    }}
    popperPlacement="bottom-end"
    stopClickPropagation
  >
    <Tooltip content={disableDeleteToken.reason || ''}>
      <MuiBox>
        <MuiMenuItem
          onClick={onDeleteToken}
          disabled={disableDeleteToken.disabled}
          disableRipple
        >
          <Typography variant="body-regular">Delete</Typography>
        </MuiMenuItem>
      </MuiBox>
    </Tooltip>
  </MenuList>
);
