export const palette = {
  naturals0: '#ffffff',
  naturals50: '#f7f7f7',
  naturals100: '#f2f2f2',
  naturals150: '#ebebeb',
  naturals200: '#e0e0e0',
  naturals300: '#bdbdbd',
  naturals400: '#9e9e9e',
  naturals500: '#616161',
  naturals600: '#474747',
  naturals650: '#303030',
  naturals700: '#272727',
  naturals750: '#212121',
  naturals800: '#1f1f1f',
  naturals900: '#141414',
  naturals1000: '#000000',

  purples900: '#31054C',
  purples800: '#410B65',
  purples700: '#6A09AA',
  purples500: '#9D0AFF',
  purples300: '#CA83FC',
  purples200: '#F3E1FE',
  purples100: '#F9F2FD',

  blues900: '#061947',
  blues800: '#0A1F5C',
  blues700: '#0A4BCD',
  blues500: '#058FF2',
  blues300: '#82C9FC',
  blues200: '#E5F2FF',
  blues100: '#F2F6FD',
} as const;

// TODO: use proper design tokens here
// Common
export const COMMON_SELECT_FIELD_BORDER = palette.naturals100;
export const COMMON_SELECT_FIELD_BORDER_FOCUSED = palette.naturals500;
export const COMMON_MENU_LIST_BOX_SHADOW = 'rgba(0, 0, 0, 0.12)';
export const COMMON_SELECT_FIELD_ICON = palette.naturals700;

export const COMMON_TOGGLE_SWITCH_DISABLED_BG_COLOR = palette.naturals200;
export const COMMON_TOGGLE_SWITCH_INDETERMINATE_BG_COLOR = palette.naturals800;
export const COMMON_TOGGLE_SWITCH_KNOB_BG_COLOR = palette.naturals0;
export const COMMON_TOGGLE_SWITCH_OFF_BG_COLOR = palette.naturals400;
export const COMMON_TOGGLE_SWITCH_ON_BG_COLOR = palette.naturals800;
export const COMMON_TOGGLE_SWITCH_FOCUS_BORDER_COLOR = palette.naturals500;

export const ICON_BUTTON_ICON_COLOR = palette.naturals400;
export const ICON_BUTTON_ICON_HOVER_BG = palette.naturals150;
export const ICON_BUTTON_ICON_HOVER_COLOR = palette.naturals500;
export const ICON_BUTTON_ICON_FOCUS_BORDER = palette.naturals400;

// ChipsInput
export const CHIP_INPUT_BORDER_COLOR = palette.naturals100;
export const CHIP_INPUT_CHIP_BACKGROUND_COLOR = palette.naturals100;
export const CHIP_INPUT_CHIP_BACKGROUND_COLOR_HOVER = palette.naturals150;

// Tooltip
export const TOOLTIP_COLOR = palette.naturals0;

// Canvas
export const CANVAS_BACKGROUND_COLOR = '#ccc';
export const CANJUX_BACKGROUND_COLOR = palette.naturals100;

export const CANJUX_SELECTION_COLOR = '#058ff233';

// Tabs
export const TAB_HEADER_ITEM_COLOR = palette.naturals400;
export const TAB_HEADER_ITEM_SELECTED_COLOR = palette.naturals700;
export const TAB_HEADER_ITEM_HOVERED_COLOR = palette.naturals500;

// Navigator
export const NAVIGATOR_POPPER_EMPTY_TEXT_COLOR = palette.naturals500;

// NodeNameBar
export const NODE_TOOLBAR_COLOR = palette.naturals400;
export const NODE_TOOLBAR_SELECTED_COLOR = palette.naturals800;
export const NODE_TOOLBAR_COLOR_LIBRARY_COMPONENT = '#5E86D8';
export const NODE_TOOLBAR_SELECTED_COLOR_LIBRARY_COMPONENT = palette.blues700;

export const SELECT_FIELD_BOX_BORDER_COLOR = palette.naturals300;
export const DDP_FIELD_TOKENS_BOX_BORDER_COLOR = palette.naturals200;
export const DDP_FIELD_TOKENS_BOX_NO_TOKENS_TEXT_COLOR = palette.naturals500;
export const SELECT_FIELD_BOX_TEXT_COLOR = palette.naturals500;

export const LIVE_OR_EDIT_HOVER_BACKGROUND_COLOR = palette.naturals100;

export const DDP_FIELD_TOKENS_BOX_SHADOW = 'rgba(0, 0, 0, 0.12)';

// PropertiesPanel
export const PROPERTIES_PANEL_BOX_SHADOW_COLOR = palette.naturals1000;

// Modal
export const MODAL_BACKGROUND_COLOR = palette.naturals0;
export const MODAL_BORDER_COLOR = palette.naturals100;
export const MODAL_BOX_SHADOW_COLOR = palette.naturals1000;

// Navigator
export const NAVIGATOR_COMPONENT_ITEM_ICON_HOVER_COLOR = palette.naturals500;
export const NAVIGATOR_COMPONENT_ITEM_ICON_COLOR = palette.naturals400;
export const NAVIGATOR_COMPONENT_ITEM_TOOLTIP_TEXT_COLOR = palette.naturals200;
export const NAVIGATOR_COMPONENT_ITEM_ACTIONS_ICON_COLOR = palette.naturals1000;
export const NAVIGATOR_MENU_TAB_CONTENT_DESCRIPTION_COLOR = palette.naturals500;
export const NAVIGATOR_BUTTON_VARIANT_PRIMARY_IDLE_BACKGROUND_COLOR =
  palette.naturals700;
export const NAVIGATOR_BUTTON_VARIANT_PRIMARY_IDLE_BORDER_COLOR =
  palette.naturals700;
export const NAVIGATOR_BUTTON_VARIANT_PRIMARY_HOVER_BORDER_COLOR =
  palette.naturals700;
export const NAVIGATOR_BUTTON_VARIANT_PRIMARY_ACTIVE_BACKGROUND_COLOR =
  palette.naturals600;
export const NAVIGATOR_BUTTON_VARIANT_PRIMARY_FOCUSED_BORDER_COLOR =
  palette.naturals400;
export const NAVIGATOR_BUTTON_VARIANT_PRIMARY_FOCUSED_BACKGROUND_COLOR =
  palette.naturals600;
export const NAVIGATOR_BUTTON_VARIANT_PRIMARY_DISABLED_BORDER_COLOR =
  palette.naturals200;
export const NAVIGATOR_BUTTON_VARIANT_PRIMARY_DISABLED_BACKGROUND_COLOR =
  palette.naturals200;
export const NAVIGATOR_BUTTON_VARIANT_SECONDARY_IDLE_COLOR =
  palette.naturals700;
export const NAVIGATOR_BUTTON_VARIANT_SECONDARY_IDLE_BORDER_COLOR =
  palette.naturals300;
export const NAVIGATOR_BUTTON_VARIANT_SECONDARY_HOVER_BORDER_COLOR =
  palette.naturals400;
export const NAVIGATOR_BUTTON_VARIANT_SECONDARY_FOCUSED_BORDER_COLOR =
  palette.naturals400;
export const NAVIGATOR_BUTTON_VARIANT_SECONDARY_DISABLED_COLOR =
  palette.naturals200;
export const NAVIGATOR_BUTTON_VARIANT_SECONDARY_DISABLED_BORDER_COLOR =
  palette.naturals200;
export const NAVIGATOR_IS_HIDDEN_ICON_COLOR = palette.naturals400;

export const NAVIGATOR_LOGICAL_SLOT_COLOR = palette.naturals400;
export const NAVIGATOR_LOGICAL_SLOT_SEPARATOR_COLOR = palette.naturals200;
// DDP
export const EDITOR_DDP_MODULE_BORDER_COLOR = palette.naturals100;
export const EDITOR_DDP_FIELD_HOVER_BACKGROUND = palette.naturals100;
export const EDITOR_DDP_FIELD_BORDER_COLOR = palette.naturals100;
export const EDITOR_DDP_FIELD_HOVER_BORDER_COLOR = palette.naturals200;
export const EDITOR_DDP_FIELD_FOCUS_BORDER_COLOR = palette.naturals500;
export const EDITOR_DDP_FIELD_DETACH_TOKEN = palette.naturals400;
export const EDITOR_DDP_FIELD_UNIT_COLOR_OPEN = palette.naturals500;
export const EDITOR_DDP_FIELD_UNIT_HOVER_OPEN = palette.naturals200;
export const EDITOR_DDP_FIELD_UNIT_BOX_HOVER_BACKGROUND = palette.naturals100;
export const EDITOR_DDP_BUTTON_GROUP_FIELD_IDLE_COLOR = palette.naturals300;
export const EDITOR_DDP_BUTTON_GROUP_FIELD_IDLE_BORDER_COLOR =
  palette.naturals100;
export const EDITOR_DDP_BUTTON_GROUP_FIELD_HOVER_COLOR = palette.naturals400;
export const EDITOR_DDP_BUTTON_GROUP_FIELD_DISABLED_COLOR = palette.naturals150;
export const EDITOR_DDP_BUTTON_GROUP_FIELD_DEFAULT_BG_COLOR = palette.naturals0;
export const EDITOR_DDP_BUTTON_GROUP_FIELD_DISABLED_BORDER_COLOR =
  palette.naturals150;
export const EDITOR_DDP_BUTTON_GROUP_FIELD_HOVER_BORDER_COLOR =
  palette.naturals150;
export const EDITOR_DDP_INACTIVE_FIELD_VALUE_COLOR = palette.naturals400;

export const EDITOR_DDP_COLOR_FIELD_AUTOCOMPLETE_OPTION_VALUE_COLOR =
  palette.naturals400;
export const EDITOR_DDP_CHILD_FIELDS_INACTIVE_COLOR = palette.naturals200;
export const EDITOR_DDP_CHILD_FIELDS_INACTIVE_VALUE_COLOR = palette.naturals400;
export const EDITOR_DDP_TEXT_FIELD_BASE_COLOR = palette.naturals500;
export const EDITOR_DDP_TEXT_FIELD_BASE_BACKGROUND_HOVER_COLOR =
  palette.naturals150;
export const EDITOR_DDP_TEXT_FIELD_INPUT_FOCUS_COLOR = palette.naturals300;
export const EDITOR_DDP_COLOR_INDICATOR_BOX_SHADOW = palette.naturals400;
export const EDITOR_DDP_TYPOGRAPHY_FIELD_BORDER_COLOR = palette.naturals100;
export const EDITOR_DDP_FIELD_TOKEN_SELECTED_VALUE = palette.naturals500;
export const EDITOR_DDP_COLOR_FIELD_AUTOCOMPLETE_INPUT_ICON_COLOR =
  palette.naturals500;
export const EDITOR_DDP_FIELD_TOKEN_SELECTION_ACTIONS_BORDER_COLOR =
  palette.naturals150;
export const EDITOR_DDP_PARENT_CONTEXT_BACKGROUND_COLOR = palette.naturals50;
export const EDITOR_DDP_PARENT_CONTEXT_BORDER_COLOR = palette.naturals150;
export const EDITOR_DDP_RELATIVE_TO_BACKGROUND_COLOR = palette.naturals50;
export const EDITOR_DDP_RELATIVE_TO_BORDER_COLOR = palette.naturals150;

// Layers Panel - Object Navigator
export const LAYERS_PANEL_HEADER_BORDER_COLOR = palette.naturals100;
export const LAYERS_PANEL_OBJECT_NAVIGATOR_HEADER_ICON_HOVER_COLOR =
  palette.naturals500;
export const LAYERS_PANEL_OBJECT_NAVIGATOR_HEADER_ICON_IDLE_COLOR =
  palette.naturals300;
export const LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_EXPANDER_COLOR =
  palette.naturals400;

export const LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_IDLE_BG_COLOR =
  palette.naturals0;
export const LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_HOVERED_BORDER_COLOR =
  palette.naturals400;
export const LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_TEXT_COLOR =
  palette.naturals800;
export const LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_LIBRARY_COMPONENT_TEXT_COLOR =
  palette.blues700;
export const LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_SELECTED_BG_COLOR =
  palette.naturals150;
export const LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_LIBRARY_COMPONENT_SELECTED_BG_COLOR =
  palette.blues200;
export const LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_ANCESTOR_SELECTED_BG_COLOR =
  palette.naturals50;
export const LAYERS_PANEL_OBJECT_NAVIGATOR_ITEM_LIBRARY_COMPONENT_ANCESTOR_SELECTED_BG_COLOR =
  palette.blues100;

// TOKENS
export const TOKENS_TOKEN_TYPE_TEXT_COLOR = palette.naturals500;
export const TOKENS_TOKEN_SET_ADD_ICON_COLOR = palette.naturals500;
export const TOKENS_GROUP_HEADER_ARROW_ICON_COLOR = palette.naturals500;
export const TOKENS_GROUP_POPPER_ACTION_TEXT_COLOR = palette.naturals500;
export const TOKENS_CREATE_GROUP_BUTTON_TEXT_COLOR = palette.naturals500;
export const DEFAULT_BOX_SHADOW_COLOR = palette.naturals1000;
export const TOKENS_IN_USED_BY_COMPONENTS_BORDER_COLOR = palette.naturals150;
export const TOKENS_IN_USED_BY_COMPONENTS_BACKGROUND_COLOR = palette.naturals50;
export const TOKENS_IN_USED_BY_COMPONENTS_TEXT_COLOR = palette.naturals500;

export const TRANSPARENCY_PATTERN_BACKGROUND =
  'url(data:image/svg+xml;utf8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M0%200H3V3H0V0Z%22%20fill%3D%22%23E1E1E1%22/%3E%3Cpath%20d%3D%22M3%200H6V3H3V0Z%22%20fill%3D%22white%22/%3E%3Cpath%20d%3D%22M3%203H6V6H3V3Z%22%20fill%3D%22%23E1E1E1%22/%3E%3Cpath%20d%3D%22M0%203H3V6H0V3Z%22%20fill%3D%22white%22/%3E%3C/svg%3E%0A)';

// Popper
export const POPPER_BOX_SHADOW_COLOR = 'rgba(0, 0, 0, 0.2)';
