import { FC, useRef } from 'react';
import { useHover } from 'usehooks-ts';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { getTokenDrawerBlacklistAreaAttributes } from '@jux/ui/components/tokens/token-drawer/utils/getTokenDrawerBlacklistAreaAttributes';
import { palette } from '@jux/ui/theme/palette';
import { TokenCardProps } from './TokenCard.interface';
import { TokenCardHeader } from './TokenCardHeader';
import { TokenCardContent } from './tokenCardContent/TokenCardContent';

const TOKEN_CARD_WIDTH = 250;
const TOKEN_CARD_HEIGHT = 80;

export const TokenCard: FC<TokenCardProps> = ({
  isSelected,
  onEditToken,
  onRenameToken,
  onDeleteToken,
  disableDeleteToken,
  existingTokenNamesInGroup,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const isTokenCardHovered = useHover(ref);

  return (
    <MuiGrid
      ref={ref}
      item
      container
      sx={{
        width: TOKEN_CARD_WIDTH,
        height: TOKEN_CARD_HEIGHT,
        border: '1px solid',
        borderColor: (theme) =>
          isSelected
            ? palette.naturals500
            : theme.drimz.palette.divider.primary,
        borderRadius: (theme) => theme.drimz.size.borderRadius.main,

        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={onEditToken}
      {...getTokenDrawerBlacklistAreaAttributes()}
    >
      <TokenCardHeader
        {...props}
        isTokenCardHovered={isTokenCardHovered}
        onEditToken={onEditToken}
        onDeleteToken={onDeleteToken}
        onRenameToken={onRenameToken}
        existingTokenNames={existingTokenNamesInGroup}
        disableDeleteToken={disableDeleteToken}
      />
      <MuiGrid padding="0 16px 13px" display="flex" maxWidth="100%">
        <TokenCardContent {...props} />
      </MuiGrid>
    </MuiGrid>
  );
};
