import { createSelector } from 'reselect';
import { CanjuxState, findPathInStyles } from '@jux/canjux/core';
import {
  isValidTokenPath,
  prepareTokenPathForStringifyActions,
} from '@jux/ui/utils/tokensPatterns';

export const getComponentsThatUseTokenPathSelector = createSelector(
  [(state: CanjuxState) => state.components],
  (components) => (tokenPath: string) => {
    const componentsWithTokenPath = Object.entries(components).reduce(
      (newComponents, [componentId, component]) => {
        const { styles } = component;
        if (!styles) return newComponents;

        const formattedTokenPath =
          prepareTokenPathForStringifyActions(tokenPath);

        if (isValidTokenPath(formattedTokenPath)) {
          if (findPathInStyles({ path: formattedTokenPath, styles })) {
            newComponents[componentId] = component;
            return newComponents;
          }
        }

        return newComponents;
      },
      {} as CanjuxState['components']
    );

    return componentsWithTokenPath;
  }
);
