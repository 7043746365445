import { FC, useMemo } from 'react';
import { MuiGrid } from '@jux/ui/components/common/mui';
import { Typography } from '@jux/ui/components';
import { JuxComponentData, NodeType } from '@jux/data-entities';
import {
  TOKENS_IN_USED_BY_COMPONENTS_BACKGROUND_COLOR,
  TOKENS_IN_USED_BY_COMPONENTS_BORDER_COLOR,
  TOKENS_IN_USED_BY_COMPONENTS_TEXT_COLOR,
} from '@jux/ui/theme/palette';

export const TokenUsedByComponents: FC<{
  components: Record<string, JuxComponentData>;
}> = ({ components }) => {
  const amountOfComponentsThatUsesTheTokenStr = useMemo(() => {
    const amountOfComponents = Object.keys(components).length;
    if (amountOfComponents < 1) return 'Token not in use';

    const singularOrPlural =
      amountOfComponents > 1 ? 'components' : 'component';

    return `Used in ${amountOfComponents} ${singularOrPlural}.`;
  }, [components]);

  const amountOfComponentsByTypeStr = useMemo(() => {
    if (!components) return '';

    let libraryComponentsCounter = 0;
    let localComponentsCounter = 0;

    Object.values(components).forEach(({ type }) => {
      if (type === NodeType.LIBRARY_COMPONENT) {
        libraryComponentsCounter++;
      }
      if (type === NodeType.LOCAL_COMPONENT) {
        localComponentsCounter++;
      }
    });

    const libraryComponentsStr =
      libraryComponentsCounter > 1 ? 'library components' : 'library component';
    const localComponentsStr =
      localComponentsCounter > 1 ? 'local components' : 'local component';

    if (libraryComponentsCounter > 0) {
      if (localComponentsCounter > 0) {
        return `${libraryComponentsCounter} ${libraryComponentsStr} and ${localComponentsCounter} ${localComponentsStr}.`;
      }
      return `${libraryComponentsCounter} ${libraryComponentsStr}.`;
    } else if (localComponentsCounter > 0) {
      return `${localComponentsCounter} ${localComponentsStr}.`;
    }
    return '';
  }, [components]);

  return (
    <MuiGrid container padding="16px 20px 0" gap="16px">
      <MuiGrid
        item
        container
        sx={{
          height: '1px',
          backgroundColor: (theme) => theme.drimz.palette.divider.secondary,
        }}
      />
      <MuiGrid
        item
        container
        sx={{
          border: '1px solid',
          padding: '8px',
          borderColor: TOKENS_IN_USED_BY_COMPONENTS_BORDER_COLOR,
          backgroundColor: TOKENS_IN_USED_BY_COMPONENTS_BACKGROUND_COLOR,
        }}
      >
        <Typography
          variant="label-s-medium"
          color={TOKENS_IN_USED_BY_COMPONENTS_TEXT_COLOR}
        >
          {amountOfComponentsThatUsesTheTokenStr}
          <br />
          {amountOfComponentsByTypeStr}
        </Typography>
      </MuiGrid>
    </MuiGrid>
  );
};
