import {
  JuxStore,
  JuxStoreActionFn,
  replacePathInStyles,
  TokenSetsActionsParams,
  updateComponentsWithNewStyles,
} from '@jux/canjux/core';
import { getCurrentTimestamp } from '@juxio/design-tokens';
import cloneDeep from 'lodash/cloneDeep';
import { z } from 'zod';
import { getDesignTokensParserContext } from '../../../store-utils/getDesignTokensParserContext';
import {
  isValidTokenPath,
  prepareTokenPathForStringifyActions,
} from '@jux/ui/utils/tokensPatterns';
import { Draft as WritableDraft } from 'mutative';

const replaceTokenPathInComponentsStyles = ({
  components,
  newPath,
  oldPath,
}: {
  components: WritableDraft<JuxStore['components']>;
  newPath: string;
  oldPath: string;
}) => {
  const stylesToChangeByComponentId = Object.entries(components)
    .map(([componentId, { styles }]) => {
      if (!styles) return undefined;

      const formattedOldPath = prepareTokenPathForStringifyActions(oldPath);
      const formattedNewPath = prepareTokenPathForStringifyActions(newPath);

      if (
        isValidTokenPath(formattedOldPath) &&
        isValidTokenPath(formattedNewPath)
      ) {
        const updatedStyles = replacePathInStyles({
          oldPath: formattedOldPath,
          newPath: formattedNewPath,
          styles,
        });

        if (updatedStyles) {
          return { [componentId]: updatedStyles };
        }
      }

      return undefined;
    })
    .filter(Boolean);

  updateComponentsWithNewStyles({
    components,
    stylesToChangeByComponentId,
  });
};

const designTokensParser = getDesignTokensParserContext();

export const renameToken: JuxStoreActionFn<
  TokenSetsActionsParams['renameToken'],
  JuxStore
> = ({ state, ...params }) => {
  const { newName: newPath, oldName: oldPath } = z
    .object({
      newName: z.string(),
      oldName: z.string(),
    })
    .parse(params);

  Object.values(state.tokenSets).forEach((tokenSet) => {
    tokenSet.value = designTokensParser
      .parse(cloneDeep(tokenSet.value))
      .renameToken({
        oldPath,
        newPath,
      })
      .getRawValueCopy();

    tokenSet.updatedAt = getCurrentTimestamp();
  });

  replaceTokenPathInComponentsStyles({
    components: state.components,
    newPath,
    oldPath,
  });

  return state;
};
